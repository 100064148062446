import { ORDER_TYPE, OrderType } from '../domain/schemas';

// ソートを実行した時に常に降順→昇順になるように調整する
export function orderParamDefaultDesc(sorted: boolean, order?: OrderType) {
  if (!sorted) return ORDER_TYPE.DESC;
  return order === ORDER_TYPE.ASC ? ORDER_TYPE.DESC : ORDER_TYPE.ASC;
}

// ソートを実行した時に常に昇順->降順になるように調整する
export function orderParamDefaultAsc(sorted: boolean, order?: OrderType) {
  if (!sorted) return ORDER_TYPE.DESC;
  return order === ORDER_TYPE.DESC ? ORDER_TYPE.ASC : ORDER_TYPE.DESC;
}

export const swapOrder = (order: OrderType) => {
  return order === ORDER_TYPE.DESC ? ORDER_TYPE.ASC : ORDER_TYPE.DESC;
};

export const changeOrder = (
  sort: string,
  current:
    | {
        sort?: string;
        order?: OrderType;
      }
    | undefined
): OrderType => {
  // 値がなければデフォルトが降順なので昇順にする
  if (current?.sort == null || current?.order == null) {
    return 'asc';
  }

  // sortが一致しなければデフォルトの降順
  if (sort !== current.sort) {
    return 'desc';
  }

  return current.order === 'desc' ? 'asc' : 'desc';
};
