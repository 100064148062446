import { createSelector } from 'reselect';

import { LoadingState } from '../../domain/schemas';
import {
  DataTransitionAfterIntroduction,
  DataTransitionAfterIntroductionParams,
} from '../../domain/transitionAfterIntroduction/types';

// Action Types

const SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW = 'SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW' as const;
const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW = 'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW' as const;
const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_REQUEST = 'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_REQUEST' as const;
const FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_SUCCESS = 'FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_SUCCESS' as const;
const HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW = 'HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW' as const;
const TOGGLE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW = 'TOGGLE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW' as const;
const RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW = 'RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW' as const;

export const DataTransitionAfterIntroduction2ndRowActionTypes = {
  SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_REQUEST,
  FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_SUCCESS,
  RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
  HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
  TOGGLE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
};

// Action Creators

/**
 * 現在の検索条件を元に展開行を取得する
 * @param kiCode 機種コード
 */
function searchDataTransitionAfterIntroduction2ndRowAction(kiCode: string) {
  return {
    type: SEARCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
    payload: { kiCode },
  };
}

/**
 * 指定した検索条件で展開行データを取得する
 * @param kiCode 機種コード
 * @param params 取得する展開行の検索条件
 */
function fetchDataTransitionAfterIntroduction2ndRowAction(
  kiCode: string,
  params: DataTransitionAfterIntroductionParams
) {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
    payload: { kiCode, params },
  };
}

/**
 * 展開行データ取得前に呼ぶ
 * @param kiCode 機種コード
 * @param params 取得する展開行の検索条件
 */
function fetchDataTransitionAfterIntroduction2ndRowRequestAction(
  kiCode: string,
  params: DataTransitionAfterIntroductionParams
) {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_REQUEST,
    payload: { kiCode, params },
  };
}

/**
 * 展開行データ取得成功時、取得したデータを登録する
 * @param kiCode 機種コード
 * @param dataTransitionAfterIntroduction2ndRow 取得した展開行データ
 */
function fetchDataTransitionAfterIntroduction2ndRowSuccessAction(
  kiCode: string,
  dataTransitionAfterIntroduction2ndRow: DataTransitionAfterIntroduction
) {
  return {
    type: FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_SUCCESS,
    payload: { kiCode, dataTransitionAfterIntroduction2ndRow },
  };
}

function renewDataTransitionAfterIntroduction2ndRowAction() {
  return {
    type: RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
  };
}

/**
 * kiCode を元に展開行を削除する
 */
function hideDataTransitionAfterIntroduction2ndRowAction(kiCode: string) {
  return {
    type: HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
    payload: { kiCode },
  };
}

/**
 * kiCode を元に展開行を表示・非表示する
 * @param kiCode 機種のコード
 */
function toggleDataTransitionAfterIntroduction2ndRowAction(kiCode: string) {
  return {
    type: TOGGLE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW,
    payload: { kiCode },
  };
}

export const DataTransitionAfterIntroduction2ndRowActionCreators = {
  searchDataTransitionAfterIntroduction2ndRowAction,
  fetchDataTransitionAfterIntroduction2ndRowAction,
  fetchDataTransitionAfterIntroduction2ndRowRequestAction,
  fetchDataTransitionAfterIntroduction2ndRowSuccessAction,
  renewDataTransitionAfterIntroduction2ndRowAction,
  hideDataTransitionAfterIntroduction2ndRowAction,
  toggleDataTransitionAfterIntroduction2ndRowAction,
};

// Actions

export type SearchDataTransitionAfterIntroduction2ndRowAction = ReturnType<
  typeof searchDataTransitionAfterIntroduction2ndRowAction
>;

export type FetchDataTransitionAfterIntroduction2ndRowAction = ReturnType<
  typeof fetchDataTransitionAfterIntroduction2ndRowAction
>;

export type ToggleDataTransitionAfterIntroduction2ndRowAction = ReturnType<
  typeof toggleDataTransitionAfterIntroduction2ndRowAction
>;

type DataTransitionAfterIntroduction2ndRowAction =
  | SearchDataTransitionAfterIntroduction2ndRowAction
  | FetchDataTransitionAfterIntroduction2ndRowAction
  | ToggleDataTransitionAfterIntroduction2ndRowAction
  | ReturnType<typeof fetchDataTransitionAfterIntroduction2ndRowRequestAction>
  | ReturnType<typeof fetchDataTransitionAfterIntroduction2ndRowSuccessAction>
  | ReturnType<typeof renewDataTransitionAfterIntroduction2ndRowAction>
  | ReturnType<typeof hideDataTransitionAfterIntroduction2ndRowAction>;

// State

type DataTransitionAfterIntroduction2ndRowState = {
  loadingState: {
    [key: string]: LoadingState;
  };
  dataTransitionAfterIntroduction2ndRow: {
    [key: string]: DataTransitionAfterIntroduction | undefined;
  };
};

const initialState: DataTransitionAfterIntroduction2ndRowState = {
  loadingState: {},
  dataTransitionAfterIntroduction2ndRow: {},
};

// Selector

export function dataTransitionAfterIntroduction2ndRowSelector(rootState: {
  dataTransitionAfterIntroduction2ndRow: DataTransitionAfterIntroduction2ndRowState;
}) {
  return rootState.dataTransitionAfterIntroduction2ndRow
    .dataTransitionAfterIntroduction2ndRow;
}

/**
 * kiCode に該当する展開行を取得する
 * @param kiCode 絞り込む kiCode
 * @returns 該当する展開行（該当する展開行がない場合はundefined）
 */
export const singleDataTransitionAfterIntroduction2ndRowSelector = (
  kiCode: string
) => {
  return createSelector(
    dataTransitionAfterIntroduction2ndRowSelector,
    (dataTransitionAfterIntroduction2ndRow) => {
      if (kiCode == null) {
        return;
      }

      return dataTransitionAfterIntroduction2ndRow[kiCode];
    }
  );
};

/**
 * 現在表示中の店舗行から検索条件だけ抽出したデータを取得する
 * @returns 現在表示中の店舗行の検索条件
 */
export const dataTransitionAfterIntroduction2ndRowSettingsSelector = createSelector(
  dataTransitionAfterIntroduction2ndRowSelector,
  (data) => {
    if (data == null) return {};

    const settings: {
      [key: string]: DataTransitionAfterIntroductionParams;
    } = {};

    // 現在表示中の店舗行からsettingだけ抽出したデータを作成
    Object.keys(data).forEach((key) => {
      const item = data[key];
      // 空の場合は登録しない
      if (item?.setting == null) {
        return;
      }

      settings[key] = item.setting;
    });

    return settings;
  }
);

function dataTransitionAfterIntroductionLoadingState2ndRowSelector(rootState: {
  dataTransitionAfterIntroduction2ndRow: DataTransitionAfterIntroduction2ndRowState;
}) {
  return rootState.dataTransitionAfterIntroduction2ndRow.loadingState;
}

/**
 * Loading の展開行があるかどうかを判定する
 *
 * loadingStateの中身が一つでもローディング中の場合はローディング扱いとする
 */
export const dataTransitionAfterIntroductionIsLoading2ndRowSelector = createSelector(
  dataTransitionAfterIntroductionLoadingState2ndRowSelector,
  (loadingState) => {
    const result = Object.keys(loadingState)
      .map((key) => loadingState[key])
      .filter((value) => value === 'loading');

    return result.length > 0;
  }
);

// Reducer

export function dataTransitionAfterIntroduction2ndRowReducer(
  state = initialState,
  action: DataTransitionAfterIntroduction2ndRowAction
): DataTransitionAfterIntroduction2ndRowState {
  switch (action.type) {
    case FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_REQUEST:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.kiCode]: 'loading',
        },
      };
    case FETCH_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW_SUCCESS:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.kiCode]: 'loaded',
        },
        dataTransitionAfterIntroduction2ndRow: {
          ...state.dataTransitionAfterIntroduction2ndRow,
          [action.payload.kiCode]:
            action.payload.dataTransitionAfterIntroduction2ndRow,
        },
      };
    case HIDE_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW:
      return {
        ...state,
        loadingState: {
          ...state.loadingState,
          [action.payload.kiCode]: 'prepare',
        },
        dataTransitionAfterIntroduction2ndRow: {
          ...state.dataTransitionAfterIntroduction2ndRow,
          [action.payload.kiCode]: undefined,
        },
      };
    case RENEW_DATA_TRANSITION_AFTER_INTRODUCTION_2NDROW:
      return initialState;
    default:
      return state;
  }
}
