import { AxiosResponse } from 'axios';
import { call, fork, put, takeEvery } from 'redux-saga/effects';

import { SettingsOptionsPlanProgressTransition } from '../domain/settingsOptionsPlanProgressTransition';

import {
  SettingsOptionsPlanProgressTransitionActionCreators,
  SettingsOptionsPlanProgressTransitionActionTypes,
} from '../redux/server/settingsOptionsPlanProgressTransition';
import { Api, buildConfig } from '../utils/api';
import { handleErrorSaga } from './errorSaga';

export function* fetchSettingsOptionsPlanProgressTransitionSaga(api: Api) {
  try {
    yield put(
      SettingsOptionsPlanProgressTransitionActionCreators.fetchSettingsOptionsPlanProgressTransitionRequestAction()
    );
    const response: AxiosResponse<SettingsOptionsPlanProgressTransition> = yield call(
      api.get,
      '/settings/options/planProgressTransition',
      buildConfig()
    );
    const settingsOptionsPlanProgressTransition = response.data;
    yield put(
      SettingsOptionsPlanProgressTransitionActionCreators.fetchSettingsOptionsPlanProgressTransitionSuccessAction(
        settingsOptionsPlanProgressTransition
      )
    );
  } catch (error: unknown) {
    yield put(
      SettingsOptionsPlanProgressTransitionActionCreators.renewSettingsOptionsPlanProgressTransitionAction()
    );
    yield fork(handleErrorSaga, error);
  }
}

function* handleFetchSettingsOptionsPlanProgressTransitionSaga(api: Api) {
  yield takeEvery(
    SettingsOptionsPlanProgressTransitionActionTypes.FETCH_SETTINGS_OPTIONS_PLAN_PROGRESS_TRANSITION,
    fetchSettingsOptionsPlanProgressTransitionSaga,
    api
  );
}

export function* settingsOptionsPlanProgressTransitionSagas(context: {
  api: Api;
}) {
  yield fork(handleFetchSettingsOptionsPlanProgressTransitionSaga, context.api);
}
