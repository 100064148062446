// createThemeを非推奨としているため
// 参考: https://www.notion.so/makeStyles-a9d944ea9ed54786825ef08bdb54cae9
// eslint-disable-next-line no-restricted-imports
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: { root: { color: 'rgba(0, 0, 0, 0.87)' } },
    },
    MuiTooltip: {
      styleOverrides: { tooltip: { fontSize: 14, maxWidth: 'none' } },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          color: 'rgba(0, 0, 0, 0.87)',
          border: '1px solid #A29F9A',
        },
        label: { paddingLeft: 5, paddingRight: 5, textOverflow: 'unset' },
        filled: {},
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: { paddingLeft: 8, backgroundColor: '#1D2088', color: '#FFFFFF' },
        option: {
          '&[aria-selected="true"]': {
            backgroundColor: '#1D2088 !important',
            color: '#FFFFFF !important',
            ':hover': {
              backgroundColor: '#4A4C9F !important',
              color: '[object Object] !important',
            },
          },
        },
      },
    },
    MuiDialog: { styleOverrides: { paper: { maxWidth: 960 } } },
    MuiButton: {
      styleOverrides: {
        root: { color: 'rgba(0, 0, 0, 0.87)' },
        contained: { color: '#FFFFFF' },
        outlined: {
          color: 'rgba(0, 0, 0, 0.87)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
        outlinedSecondary: {
          color: 'rgba(0, 0, 0, 0.87)',
          borderColor: 'rgba(0, 0, 0, 0.23)',
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: { root: { '&.Mui-selected': { color: '#FFFFFF' } } },
    },
    MuiIconButton: { styleOverrides: { root: { padding: 3 } } },
    MuiTab: {
      styleOverrides: { root: { minWidth: 160, padding: '6px 12px' } },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': { backgroundColor: '#ebebeb' },
          '&.Mui-selected:hover': { backgroundColor: '#ebebeb' },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#ebebeb',
            '&:hover': { backgroundColor: '#ebebeb' },
          },
        },
      },
    },
  },
  mixins: {},
  palette: {
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
    mode: 'light',
    primary: {
      main: '#1D2088',
      dark: '#14165F',
      light: '#4A4C9F',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#F39700',
      dark: '#BD7500',
      light: '#FFB63D',
      contrastText: '#FFFFFF',
    },
    divider: '#E2E0DE',
    info: { light: '#4DB4F5', main: '#21A2F3', dark: '#1771AA' },
    error: { main: '#F44336', dark: '#E31B0C', light: '#F88078' },
    warning: { main: '#FFD500', dark: '#B29500', light: '#FFDD33' },
    success: { main: '#4CAF50', dark: '#3B873F', light: '#7BC67E' },
    grey: {
      '50': '#FBFAF8',
      '100': '#F5F5F5',
      '200': '#EFEDEA',
      '300': '#E2E0DE',
      '400': '#C0BDBA',
      '500': '#A29F9A',
      '600': '#7A7670',
      '700': '#65625D',
      '800': '#45433F',
      '900': '#222120',
    },
    background: { default: '#FFFFFF' },
  },
});
