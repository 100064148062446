import { ModelReportsFavorite } from '../domain/model/types';
import { MAIN_FIELD_TYPE } from '../domain/schemas';

/**
 * 検索出力が「SISタイプ・メーカー別」の場合には、展開行やグラフ、テーブルなどを非表示
 */
export const isOpenModelReportsFavorite = (
  visibleMenuType:
    | 'dataKi3rdRow'
    | 'dataKiGraph'
    | 'dataKiDai'
    | 'dataKiKasidama',
  modelReportsFavorite: ModelReportsFavorite
) => {
  let isOpenFavorite = false;
  const mainField =
    modelReportsFavorite.searchCondition.mainField ??
    MAIN_FIELD_TYPE.KI_TUSHO_MEI;
  if (mainField === MAIN_FIELD_TYPE.KI_TUSHO_MEI) {
    return true;
  }
  // 展開行の開閉を判定
  if (visibleMenuType === 'dataKi3rdRow') {
    const dataKi3rdRows = modelReportsFavorite.dataKi3rdRow;
    // SISタイプ・メーカー別展開行が機種のものを取得
    const filterDataKiTushoMei3rdRow =
      dataKi3rdRows &&
      dataKi3rdRows.filter(
        (dataKi3rdRow) =>
          dataKi3rdRow.setting !== undefined &&
          dataKi3rdRow.setting.mainField === MAIN_FIELD_TYPE.KI_TUSHO_MEI
      );
    // 第二展開のお気に入りの検索出力が「SISタイプ・メーカー別の展開行が機種のもの」かどうか確認
    // 「SISタイプ・メーカー別の第二展開行が機種のもの」は展開するものとして対象なのでtrue
    if (
      filterDataKiTushoMei3rdRow &&
      filterDataKiTushoMei3rdRow?.length !== 0
    ) {
      isOpenFavorite = true;
    }
  }
  // 推移グラフの開閉を判定
  if (visibleMenuType === 'dataKiGraph') {
    const dataKiGraphSetting = modelReportsFavorite.dataKiGraph?.setting;
    const filterDataKiGraph =
      dataKiGraphSetting &&
      Object.keys(dataKiGraphSetting).filter((key) => key !== undefined);
    if (filterDataKiGraph && filterDataKiGraph?.length !== 0) {
      isOpenFavorite = true;
    }
  }
  // 台別データの開閉を判定
  if (visibleMenuType === 'dataKiDai') {
    const dataKiDaiSetting = modelReportsFavorite.dataKiDai?.setting;
    const filterDataKiDai =
      dataKiDaiSetting &&
      Object.keys(dataKiDaiSetting).filter((key) => key !== undefined);
    if (filterDataKiDai && filterDataKiDai?.length !== 0) {
      isOpenFavorite = true;
    }
  }
  // チェーン店一覧の開閉を判定
  if (visibleMenuType === 'dataKiKasidama') {
    const dataKiKasidamaSetting = modelReportsFavorite.dataKiKasidama?.setting;
    const filterDataKiKasidama =
      dataKiKasidamaSetting &&
      Object.keys(dataKiKasidamaSetting).filter((key) => key !== undefined);
    if (filterDataKiKasidama && filterDataKiKasidama?.length !== 0) {
      isOpenFavorite = true;
    }
  }
  return isOpenFavorite;
};
