import { ORDER_TYPE, OrderType } from '../domain/schemas';
import { SettingsModesDetails } from '../domain/settingsModes';

import { sortBy } from './sortBy';

export const getModesSettingsSortedData = (
  data: SettingsModesDetails | undefined,
  order: OrderType,
  sort: string
) => {
  if (!data) {
    return undefined;
  }
  const sortIndex = data.data.columns.findIndex((column) => {
    return column === sort;
  });
  const sortedRows =
    order === ORDER_TYPE.ASC
      ? sortBy(data.data.rows, (item) => {
          // 台番号の場合は数値としてソートする
          if (sortIndex === 2) {
            return Number(item[sortIndex]);
          }
          return item[sortIndex];
        })
      : sortBy(
          // 降順の場合もnullを一番下にする
          sortBy(data.data.rows, (item) => {
            // 台番号の場合は数値としてソートする
            if (sortIndex === 2) {
              return Number(item[sortIndex]);
            }
            return item[sortIndex];
          }).reverse(),
          (item) => {
            if (item[sortIndex] == null) {
              return 1;
            } else {
              return 0;
            }
          }
        );
  return { data: { ...data.data, rows: sortedRows } };
};
