import { createSelector } from 'reselect';

import {
  DataDepreciationResponse,
  DataDepreciationSearchParams,
} from '../../domain/depreciation/types';
import { LoadingState } from '../../domain/schemas';

/* ---------------------------------------------------------------
 * Action Types
 */

const FETCH_DATA_DEPRECIATION = 'FETCH_DATA_DEPRECIATION' as const;
const FETCH_DATA_DEPRECIATION_REQUEST = 'FETCH_DATA_DEPRECIATION_REQUEST' as const;
const FETCH_DATA_DEPRECIATION_SUCCESS = 'FETCH_DATA_DEPRECIATION_SUCCESS' as const;
const RENEW_DATA_DEPRECIATION = 'RENEW_DATA_DEPRECIATION' as const;

export const DataDepreciationActionTypes = {
  FETCH_DATA_DEPRECIATION,
  FETCH_DATA_DEPRECIATION_REQUEST,
  FETCH_DATA_DEPRECIATION_SUCCESS,
  RENEW_DATA_DEPRECIATION,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

function fetchDataDepreciationAction(params?: DataDepreciationSearchParams) {
  return {
    type: FETCH_DATA_DEPRECIATION,
    payload: { params },
  };
}

function fetchDataDepreciationRequestAction() {
  return {
    type: FETCH_DATA_DEPRECIATION_REQUEST,
  };
}

function fetchDataDepreciationSuccessAction(
  dataDepreciation: DataDepreciationResponse
) {
  return {
    type: FETCH_DATA_DEPRECIATION_SUCCESS,
    payload: { dataDepreciation },
  };
}

function renewDataDepreciationAction() {
  return {
    type: RENEW_DATA_DEPRECIATION,
  };
}

export const DataDepreciationActionCreators = {
  fetchDataDepreciationAction,
  fetchDataDepreciationRequestAction,
  fetchDataDepreciationSuccessAction,
  renewDataDepreciationAction,
};

/* ---------------------------------------------------------------
 * Actions
 */

export type FetchDataDepreciationAction = ReturnType<
  typeof fetchDataDepreciationAction
>;
type FetchDataDepreciationRequestAction = ReturnType<
  typeof fetchDataDepreciationRequestAction
>;
type FetchDataDepreciationSuccessAction = ReturnType<
  typeof fetchDataDepreciationSuccessAction
>;
type RenewDataDepreciationAction = ReturnType<
  typeof renewDataDepreciationAction
>;

type DataDepreciationAction =
  | FetchDataDepreciationAction
  | FetchDataDepreciationRequestAction
  | FetchDataDepreciationSuccessAction
  | RenewDataDepreciationAction;

/* ---------------------------------------------------------------
 * State
 */

type DataDepreciationState = {
  loadingState: LoadingState;
  dataDepreciation?: DataDepreciationResponse;
};

const initialState: DataDepreciationState = {
  loadingState: 'prepare',
  dataDepreciation: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/**
 * 機種別償却 全ての状態を取得する
 */
function dataDepreciationSelector(rootState: {
  dataDepreciation: DataDepreciationState;
}) {
  return rootState.dataDepreciation;
}

/**
 * 機種別償却 データのローディング状態を取得する
 * @returns ローディング状態
 */
export const dataDepreciationLoadingStateSelector = createSelector(
  dataDepreciationSelector,
  ({ loadingState }) => loadingState
);

/**
 * 機種別償却 データを取得する
 * @returns データ
 */
export const dataDepreciationDataSelector = createSelector(
  dataDepreciationSelector,
  ({ dataDepreciation }) => dataDepreciation
);

/* ---------------------------------------------------------------
 * Reducer
 */

export function dataDepreciationReducer(
  state = initialState,
  action: DataDepreciationAction
): DataDepreciationState {
  switch (action.type) {
    case FETCH_DATA_DEPRECIATION_REQUEST:
      return {
        ...state,
        loadingState: 'loading',
      };
    case FETCH_DATA_DEPRECIATION_SUCCESS:
      return {
        ...state,
        loadingState: 'loaded',
        dataDepreciation: action.payload.dataDepreciation,
      };
    case RENEW_DATA_DEPRECIATION:
      return initialState;
    default:
      return state;
  }
}
