import { DataPlanProgressTransition } from '../dataPlanProgressTransition';
import { planProgressTransitionDateRangeDateRanges } from './consistent';
import { PlanProgressTransitionReportsSettingDateRangeParams } from './types';

// 予定進捗推移のデフォルトデータ
export const dataPlanProgressTransitionDefaultValue = (): {
  setting: Partial<DataPlanProgressTransition['setting']>;
  data: DataPlanProgressTransition['data'];
  dateRangeParams: PlanProgressTransitionReportsSettingDateRangeParams;
} => {
  const current = new Date();

  const dateRange = planProgressTransitionDateRangeDateRanges[1]; // MEMO: 期間のデフォルトは'今月'

  return {
    setting: {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      excludeToday: true,
    },
    data: {
      columns: [],
      rows: [],
    },
    dateRangeParams: {
      dateRange,
    },
  };
};
