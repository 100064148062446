import { dateRangeToStartEnd } from '../../utils/dateRangeToStartEnd';
import { modeDateRanges } from './consistent';
import { ModeDateRangeParams, ModeSearchParams } from './types';

/**
 * [モード別集計] 検索パラメータに渡す日付データを算出する
 */
export const makeModeDateFromDateRange = ({
  dateRangeParams,
  searchParams,
  baseDate = new Date(),
}: {
  dateRangeParams: ModeDateRangeParams;
  searchParams: ModeSearchParams;
  baseDate?: Date;
}):
  | {
      startDate: string;
      endDate: string;
      startComparisonDate: string;
      endComparisonDate: string;
    }
  | {
      ymdList: string[];
      ymdComparisonList: string[];
    } => {
  // カスタムの場合、searchParamsの選択されている日付情報をそのまま戻す
  const dateRange = dateRangeParams.dateRange;
  if (dateRange === 'カスタム') {
    if (
      searchParams.startDate != null &&
      searchParams.endDate != null &&
      searchParams.startComparisonDate != null &&
      searchParams.endComparisonDate != null
    ) {
      return {
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        startComparisonDate: searchParams.startComparisonDate,
        endComparisonDate: searchParams.endComparisonDate,
      };
    }
    return {
      ymdList: searchParams.ymdList ?? [],
      ymdComparisonList: searchParams.ymdComparisonList ?? [],
    };
  }

  return makeModeDateFromDateRangeWithoutCustom({
    dateRangeParams: { ...dateRangeParams, dateRange },
    baseDate,
  });
};

/**
 * [モード別集計] 検索パラメータに渡す日付データを算出する、ただしカスタムは考慮しない
 */
export const makeModeDateFromDateRangeWithoutCustom = ({
  dateRangeParams,
  baseDate = new Date(),
}: {
  dateRangeParams: ModeDateRangeParams & {
    dateRange: Exclude<ModeDateRangeParams['dateRange'], 'カスタム'>;
  };
  baseDate?: Date;
}): {
  startDate: string;
  endDate: string;
  startComparisonDate: string;
  endComparisonDate: string;
} => {
  const { startDate, endDate } = dateRangeToStartEnd(
    dateRangeParams.dateRange,
    baseDate
  );

  // 過去のお気に入りには比較区分がないため、その場合にはデフォルト値を利用する
  const comparisonSection =
    dateRangeParams.comparisonSection ??
    modeDateRanges.find(({ name }) => name === dateRangeParams.dateRange)
      ?.defaultComparisonSection;

  if (comparisonSection == null || comparisonSection === 'カスタム') {
    throw new Error(`指定された比較区分 ${comparisonSection} は未実装です`);
  }

  if (comparisonSection === '平日/土日祝比較') {
    return {
      startDate,
      endDate,
      startComparisonDate: startDate,
      endComparisonDate: endDate,
    };
  }

  const {
    startDate: startComparisonDate,
    endDate: endComparisonDate,
  } = dateRangeToStartEnd(comparisonSection, new Date(startDate));

  return {
    startDate: startDate,
    endDate: endDate,
    startComparisonDate,
    endComparisonDate,
  };
};
