import { eachDayOfInterval, format, isAfter } from 'date-fns';

import { SIS_TYPE_UPDATE_DATES } from '../../consistents';
import { selectShu2SearchCondition } from '../../utils/shu';
import { updateSisTypesV1 } from '../../utils/updateSisTypes';
import { makeModeDateFromDateRange } from './date';
import { ModeFavorite, ModeFavoriteV1, ModeSearchParams } from './types';

/**
 * ModeFavoriteがModeFavoriteV1かどうかを判定
 */
const isFavoriteV1 = (favorite: ModeFavorite): favorite is ModeFavoriteV1 => {
  return 'searchParams' in favorite;
};

/**
 * お気に入りデータのsearchConditionをsearchParamsに変更
 *
 * searchConditionがあればsearchConditionの値をsearchParamsに移動させ、searchConditionを削除
 * currentShuおよびcurrentKiListもsearchParamsに移動して、元のデータを削除
 */
const migrateFavoriteSearchCondition = (
  favorite: ModeFavorite
): ModeFavoriteV1 => {
  if (isFavoriteV1(favorite)) {
    return favorite;
  }

  const { searchCondition, currentShu, currentKiList, ...rest } = favorite;

  return {
    ...rest,
    selectedKiList: searchCondition?.kiList ?? [],
    searchParams: {
      ...searchCondition,
      ...(currentShu ? selectShu2SearchCondition(currentShu) : {}),
      kiList: currentKiList,
    },
  };
};

/**
 * お気に入りデータのマイグレーション
 */
export const migrateFavorite = (
  deprecatedFavorite: ModeFavorite,
  lastUpdatedAt: string
): ModeFavoriteV1 => {
  const favorite = migrateFavoriteSearchCondition(deprecatedFavorite);

  // モード別集計のリリース時点ではisAfterがfalseになることはないが、今後V2以降のリリースがある場合はここを修正する必要がある
  const sisTypes = isAfter(
    new Date(lastUpdatedAt),
    new Date(SIS_TYPE_UPDATE_DATES.v1)
  )
    ? favorite.searchParams?.sisTypes
    : updateSisTypesV1(favorite.searchParams?.sisTypes);

  // バグが混入した過去のお気に入りの救済処置
  // 自由選択でstart / endになるお気に入りが作成された可能性があるため、選択期間を包括するymdに変換してリクエストと選択状態の整合性を合わせる
  // 関連: https://github.com/DKClaris/claris-general/issues/4792
  if (
    favorite.selectedDateRangeParams.dateUnit === '自由選択' &&
    favorite.searchParams.startDate &&
    favorite.searchParams.endDate &&
    favorite.searchParams.startComparisonDate &&
    favorite.searchParams.endComparisonDate
  ) {
    const {
      startDate: _0,
      endDate: _1,
      startComparisonDate: _2,
      endComparisonDate: _3,
      ...restSearchParams
    } = favorite.searchParams;

    return {
      ...favorite,
      searchParams: {
        ...restSearchParams,
        ymdList: eachDayOfInterval({
          start: new Date(favorite.searchParams.startDate),
          end: new Date(favorite.searchParams.endDate),
        }).map((date) => format(date, 'yyyy-MM-dd')),
        ymdComparisonList: eachDayOfInterval({
          start: new Date(favorite.searchParams.startComparisonDate),
          end: new Date(favorite.searchParams.endComparisonDate),
        }).map((date) => format(date, 'yyyy-MM-dd')),
        sisTypes,
      },
    };
  }

  return {
    ...favorite,
    searchParams: {
      ...favorite.searchParams,
      sisTypes,
    },
  };
};

/**
 * お気に入りのsearchParamsをdataRangeParamsを元に再計算する
 *
 * 前日でお気に入り登録した場合、保存されているsearchParamsのstartDateはお気に入りを実行した日の前日になる必要があります
 */
export const updateFavoriteSearchParams = (
  favorite: ModeFavoriteV1
): ModeSearchParams => {
  const { dateRange } = favorite.selectedDateRangeParams;
  if (dateRange === 'カスタム') {
    return favorite.searchParams ?? {};
  }

  const date = makeModeDateFromDateRange({
    dateRangeParams: favorite.selectedDateRangeParams,
    searchParams: favorite.searchParams ?? {},
  });
  const {
    startDate: _0,
    endDate: _1,
    startComparisonDate: _2,
    endComparisonDate: _3,
    ymdList: _4,
    ymdComparisonList: _5,
    ...rest
  } = favorite.searchParams ?? {};

  return {
    ...rest,
    ...date,
  };
};
