import { format } from 'date-fns';

import {
  DataHallShu,
  DataHallShuParams,
  DataHallShuSummary,
} from '../domain/dataHallShu';
import { HallReportsSettingDateRangeParams } from '../domain/hallReportsSettingDateRangeParams';
import { ORDER_TYPE } from '../domain/schemas';

import {
  makeDateFromDateUnitAndRange,
  reportsDateRanges,
  reportsDateUnits,
} from './reportsDateRange';

// 店舗レポートの検索条件の初期値
export const dataHallDefaultValue = (): {
  setting: Partial<DataHallShu['setting']>;
  data: DataHallShu['data'];
  dateRangeParams: HallReportsSettingDateRangeParams;
} => {
  const current = new Date();
  const dateUnit = reportsDateUnits[0];
  const dateRange = reportsDateRanges[0];

  // 指定された期間から日付を算出する
  const date = makeDateFromDateUnitAndRange(dateRange, dateUnit, current);
  const ymdList = date.ymdList.map((date) => format(date, 'yyyy-MM-dd'));
  const ymdComparisonList = date.ymdComparisonList.map((date) =>
    format(date, 'yyyy-MM-dd')
  );

  return {
    setting: {
      isFiltering: false,
      excludeToday: true,
      order: ORDER_TYPE.DESC,
      ymdList,
      ymdComparisonList,
    },
    data: {
      columns: [],
      rows: [],
    },
    dateRangeParams: {
      dateUnit,
      dateRange,
      isComparison: true,
    },
  };
};

// 店舗レポートの検索条件の初期値
export const dataHallSettingDefaultValue = (): DataHallShuParams => {
  return {
    isFiltering: false,
    excludeToday: true,
    order: ORDER_TYPE.DESC,
    ymdList: makeDateFromDateUnitAndRange(
      '前日',
      '日',
      new Date()
    ).ymdList.map((date) => format(date, 'yyyy-MM-dd')),
    ymdComparisonList: makeDateFromDateUnitAndRange(
      '前日',
      '日',
      new Date()
    ).ymdComparisonList.map((date) => format(date, 'yyyy-MM-dd')),
  };
};

// 店舗レポート種別実績集計のデフォルトデータ
export const dataHallShuDefaultValue = (): DataHallShu => {
  return {
    setting: {
      ...dataHallSettingDefaultValue(),
      fields: [
        'shu',
        'days',
        'daisu',
        'out',
        'outYoteiSa',
        'uriageGk1000Base',
        'uriageYoteiSa1000Base',
        'arariGk1000Base',
        'arariYoteiSa1000Base',
        'riekiRitu',
        'monthlyUriage1000Base',
        'monthlyUriageAchievementRate',
        'monthlyArari1000Base',
        'monthlyArariAchievementRate',
      ],
    },
    data: {
      columns: [],
      rows: [],
    },
  };
};

// 店舗レポート種別実績（Summary）のデフォルトデータ
export const dataHallShuSummaryDefaultValue = (): DataHallShuSummary => {
  return {
    setting: {
      ymdList: makeDateFromDateUnitAndRange(
        '前日',
        '日',
        new Date()
      ).ymdList.map((date) => format(date, 'yyyy-MM-dd')),
      excludeToday: true,
    },
    data: {
      termActualVsForecast: {
        sales: {
          name: '',
          original: { value: '', unit: '', color: '#000000' },
          comparison: { value: '', unit: '', color: '#000000' },
        },
        profit: {
          name: '',
          original: { value: '', unit: '', color: '#000000' },
          comparison: { value: '', unit: '', color: '#000000' },
        },
        profitRate: {
          name: '',
          original: { value: '', unit: '', color: '#000000' },
          comparison: { value: '', unit: '', color: '#000000' },
        },
      },
      monthlyProgress: {
        sales: {
          name: '',
          original: { value: '', unit: '', color: '#000000' },
          comparison: { value: '', unit: '', color: '#000000' },
        },
        salesAchievementRate: {
          name: '',
          original: { value: '', unit: '', color: '#000000' },
        },
        profit: {
          name: '',
          original: { value: '', unit: '', color: '#000000' },
          comparison: { value: '', unit: '', color: '#000000' },
        },
        profitAchievementRate: {
          name: '',
          original: { value: '', unit: '', color: '#000000' },
        },
      },
    },
  };
};
