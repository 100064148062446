import { createSelector } from 'reselect';

import { DataKiDai, DataKiDaiParams } from '../../domain/dataKiDai';
import { MarkingOption } from '../../domain/marking';
import {
  MAIN_FIELD_TYPE,
  ORDER_TYPE,
  Option,
  OrderType,
  QueryParameter,
} from '../../domain/schemas';

import { RootState } from '../../store';
import { ModelReportsSettingState } from '../ui/modelReportsSetting';
import {
  dataKiSelectOptionsSelector,
  dataKiSettingMainFieldSelector,
} from './dataKi';

// Action Types

const SEARCH_DATA_KI_DAI = 'SEARCH_DATA_KI_DAI' as const;
const SEARCH_DATA_KI_DAI_KI = 'SEARCH_DATA_KI_DAI_KI' as const;
const SEARCH_DATA_KI_DAI_SORT = 'SEARCH_DATA_KI_DAI_SORT' as const;
const SEARCH_DATA_KI_DAI_MARKING = 'SEARCH_DATA_KI_DAI_MARKING' as const;
const SEARCH_DATA_KI_DAI_FIELD = 'SEARCH_DATA_KI_DAI_FIELD' as const;

const FETCH_DATA_KI_DAI = 'FETCH_DATA_KI_DAI' as const;
const FETCH_DATA_KI_DAI_REQUEST = 'FETCH_DATA_KI_DAI_REQUEST' as const;
const FETCH_DATA_KI_DAI_SUCCESS = 'FETCH_DATA_KI_DAI_SUCCESS' as const;

const HIDE_DATA_KI_DAI = 'HIDE_DATA_KI_DAI' as const;

const RENEW_DATA_KI_DAI = 'RENEW_DATA_KI_DAI' as const;

export const DataKiDaiActionTypes = {
  SEARCH_DATA_KI_DAI,
  SEARCH_DATA_KI_DAI_KI,
  SEARCH_DATA_KI_DAI_SORT,
  SEARCH_DATA_KI_DAI_MARKING,
  SEARCH_DATA_KI_DAI_FIELD,
  FETCH_DATA_KI_DAI,
  FETCH_DATA_KI_DAI_REQUEST,
  FETCH_DATA_KI_DAI_SUCCESS,
  HIDE_DATA_KI_DAI,
  RENEW_DATA_KI_DAI,
};

// Action Creators

/**
 * クリックしたセルの台別データを取得する
 * @param mainField 機種コード/メーカーコード/SISタイプのいずれかのQueryParameter
 * @param hall 店舗コードのQueryParameter
 */
function searchDataKiDaiAction(
  mainField: QueryParameter,
  hall?: QueryParameter,
  requestParams?: {
    hallId: string;
    mainFieldId: string;
  },
  dataKi1stRow?: QueryParameter
) {
  return {
    type: SEARCH_DATA_KI_DAI,
    payload: { mainField, hall, requestParams, dataKi1stRow },
  };
}

/**
 * 選択した機種の台別データを取得する（種別選択フォーム）
 * @param mainField 機種コード/メーカーコード/SISタイプのいずれかのQueryParameter
 */
function searchDataKiDaiKiAction(mainField: QueryParameter) {
  return {
    type: SEARCH_DATA_KI_DAI_KI,
    payload: { mainField },
  };
}

/**
 * 指定したソート条件で台別データを再取得する
 * @param sort ソートする条件
 * @param order ソート順
 */
function searchDataKiDaiSortAction(sort: string, order: OrderType) {
  return {
    type: SEARCH_DATA_KI_DAI_SORT,
    payload: { sort, order },
  };
}

/**
 * 指定したマーキング条件で台別データを再取得する
 * @param markingOption マーキング項目
 * @param isFiltering 該当行のみ表示するか
 */
function searchDataKiDaiMarkingAction(
  markingOption: MarkingOption,
  isFiltering: boolean
) {
  return {
    type: SEARCH_DATA_KI_DAI_MARKING,
    payload: { markingOption, isFiltering },
  };
}

/**
 * 指定した表示項目の台別データを再取得する
 * @param fields 表示項目
 */
function searchDataKiDaiFieldAction(fields: Option[]) {
  return {
    type: SEARCH_DATA_KI_DAI_FIELD,
    payload: { fields },
  };
}

/**
 * 台別データを取得する
 * @param params 検索条件
 */
export function fetchDataKiDaiAction(
  params: DataKiDaiParams,
  selectedDataKiExpandedRow?: ModelReportsSettingState['selectedDataKiExpandedRow']
) {
  return {
    type: FETCH_DATA_KI_DAI,
    payload: { params, selectedDataKiExpandedRow },
  };
}

/**
 * 台別データ取得開始時に実行
 */
function fetchDataKiDaiRequestAction() {
  return {
    type: FETCH_DATA_KI_DAI_REQUEST,
  };
}

/**
 * 台別データ取得成功時に実行
 * @param dataKiDai 取得した台別データ
 */
function fetchDataKiDaiSuccessAction(dataKiDai: DataKiDai) {
  return {
    type: FETCH_DATA_KI_DAI_SUCCESS,
    payload: { dataKiDai },
  };
}

/**
 * 台別データを閉じる
 */
function hideDataKiDai() {
  return {
    type: HIDE_DATA_KI_DAI,
  };
}

function renewDataKiDaiAction() {
  return {
    type: RENEW_DATA_KI_DAI,
  };
}

export const DataKiDaiActionCreators = {
  searchDataKiDaiAction,
  searchDataKiDaiKiAction,
  searchDataKiDaiSortAction,
  searchDataKiDaiMarkingAction,
  searchDataKiDaiFieldAction,
  fetchDataKiDaiAction,
  fetchDataKiDaiRequestAction,
  fetchDataKiDaiSuccessAction,
  hideDataKiDai,
  renewDataKiDaiAction,
};

// Actions

export type SearchDataKiDaiAction = ReturnType<typeof searchDataKiDaiAction>;
export type SearchDataKiDaiKiAction = ReturnType<
  typeof searchDataKiDaiKiAction
>;
export type SearchDataKiDaiSortAction = ReturnType<
  typeof searchDataKiDaiSortAction
>;
export type SearchDataKiDaiMarkingAction = ReturnType<
  typeof searchDataKiDaiMarkingAction
>;
export type SearchDataKiDaiFieldAction = ReturnType<
  typeof searchDataKiDaiFieldAction
>;
export type FetchDataKiDaiAction = ReturnType<typeof fetchDataKiDaiAction>;
type HideDataKiDai = ReturnType<typeof hideDataKiDai>;

type DataKiDaiAction =
  | SearchDataKiDaiAction
  | SearchDataKiDaiSortAction
  | SearchDataKiDaiMarkingAction
  | SearchDataKiDaiFieldAction
  | FetchDataKiDaiAction
  | HideDataKiDai
  | ReturnType<typeof fetchDataKiDaiRequestAction>
  | ReturnType<typeof fetchDataKiDaiSuccessAction>
  | ReturnType<typeof renewDataKiDaiAction>;

// State

type DataKiDaiState = {
  dataKiDai?: DataKiDai;
  isLoading: boolean;
};

export const initialState: DataKiDaiState = {
  dataKiDai: undefined,
  isLoading: false,
};

// Selector

/**
 * 台別データすべてを取得する
 * @returns 台別データ（未取得の場合undefined）
 */
const dataKiDaiSelector = (state: RootState) => state.dataKiDai.dataKiDai;

/**
 * 台別データのローディング状態を取得する
 * @returns ローディング状態（ローディング中の場合true）
 */
export const dataKiDaiLoadingSelector = (state: RootState) =>
  state.dataKiDai.isLoading;

/**
 * 台別データの現在の検索条件
 * @returns 検索条件
 */
export const dataKiDaiParamsSelector = (state: RootState) =>
  state.dataKiDai.dataKiDai?.setting;

/**
 * 台別データのテーブルデータを取得
 * @returns テーブルデータ
 */
export const dataKiDaiTableDataSelector = createSelector(
  dataKiDaiSelector,
  (dataKiDai) => {
    const tableData = dataKiDai?.data;

    if (tableData === undefined)
      return {
        columns: [],
        rows: [],
      };

    return tableData;
  }
);

/**
 * 機種集計の台別テーブルデータのカラムのみ取得
 * @returns テーブルデータのカラム
 */
export const dataKiDaiTableDataColumnsSelector = createSelector(
  dataKiDaiTableDataSelector,
  (data) => {
    return data.columns;
  }
);

/**
 * 台別データのデータが存在するか取得
 * @returns 存在するか（存在する場合true）
 */
export const dataKiDaiIsExistSelector = createSelector(
  dataKiDaiSelector,
  (dataKiDai) => dataKiDai?.data !== undefined
);

/**
 * 現在の並び替えキーを取得する
 * @returns 並び替えキー（空の場合はデフォルトの並び替えキー）
 */
export const dataKiDaiParamsSortSelector = createSelector(
  dataKiDaiParamsSelector,
  (params) => {
    const sort = params?.sort;
    return sort ? sort : 'daiBan';
  }
);

/**
 * 現在の並び方を取得する
 * @returns 並び方（空の場合はデフォルトの降順）
 */
export const dataKiDaiParamsOrderSelector = createSelector(
  dataKiDaiParamsSelector,
  (params) => {
    const order = params?.order;
    return order ? order : ORDER_TYPE.DESC;
  }
);

/**
 * 現在選択されている機種を取得する
 * @returns 選択されている機種（空の場合はundefined）
 */
export const dataKiDaiParamsSelectedItemSelector = createSelector(
  [
    dataKiDaiParamsSelector,
    dataKiSelectOptionsSelector,
    dataKiSettingMainFieldSelector,
  ],
  (params, options, mainField) => {
    switch (mainField) {
      case MAIN_FIELD_TYPE.KI_TUSHO_MEI: {
        const kiList = params?.kiList;
        return kiList
          ? options.find((option) => option.value === kiList[0])
          : undefined;
      }
      case MAIN_FIELD_TYPE.TYPE: {
        const sisTypes = params?.sisTypes;
        return sisTypes
          ? options.find((option) => option.value === sisTypes[0])
          : undefined;
      }
      case MAIN_FIELD_TYPE.MAKER: {
        const makers = params?.makers;
        return makers
          ? options.find((option) => option.value === makers[0])
          : undefined;
      }
    }
  }
);

/**
 * 現在選択中の表示項目を取得する
 * @returns 選択中の表示項目
 */
export const dataKiDaiParamsFieldsSelector = createSelector(
  [dataKiDaiParamsSelector],
  (params) => {
    const selectedFields = params?.fields;

    return selectedFields ? selectedFields : [];
  }
);

// Reducer

export function dataKiDaiReducer(
  state = initialState,
  action: DataKiDaiAction
): DataKiDaiState {
  switch (action.type) {
    case FETCH_DATA_KI_DAI_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_DATA_KI_DAI_SUCCESS:
      return {
        isLoading: false,
        dataKiDai: action.payload.dataKiDai,
      };
    case HIDE_DATA_KI_DAI:
      return {
        ...state,
        dataKiDai: initialState.dataKiDai,
      };
    case RENEW_DATA_KI_DAI:
      return initialState;
    default:
      return state;
  }
}
