import { format } from 'date-fns';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';

import {
  DataTerminalTransition,
  DataTerminalTransitionParams,
} from '../domain/dataTerminalTransition';
import { DataTerminalTransitionGraphParams } from '../domain/dataTerminalTransitionGraph';
import { FavoriteItem } from '../domain/favorites';
import { MODEL_REPORT_MARKING_CONDITIONS } from '../domain/marking';
import { Column, LoadingState } from '../domain/schemas';
import { ShuOption } from '../domain/shu';
import { TerminalTransitionFavorite } from '../domain/terminalTransition/types';
import { TerminalTransitionReportsSettingDateRangeParams } from '../domain/terminalTransitionReportsSettingDateRangeParams';

import {
  DataTerminalTransitionActionCreators,
  dataTerminalTransitionColumnsForTransitionSelector,
  dataTerminalTransitionLoadingStateSelector,
  dataTerminalTransitionSelector,
  dataTerminalTransitionSettingsSelector,
} from '../redux/server/dataTerminalTransition';
import {
  DataTerminalTransition2ndRowActionCreators,
  dataTerminalTransition2ndRowSettingsSelector,
} from '../redux/server/dataTerminalTransition2ndRow';
import {
  DataTerminalTransitionGraphActionCreators,
  dataTerminalTransitionGraphSearchConditionSelector,
} from '../redux/server/dataTerminalTransitionGraph';
import { ShortenedUrlActionCreators } from '../redux/server/shortenedUrl';
import { ErrorActionCreators } from '../redux/ui/error';
import { SettingsFavoritesActionCreators } from '../redux/ui/settingsFavorites';
import {
  ChangeTerminalTransitionReportsCurrentHallsAction,
  CreateTerminalTransitionReportsShortenedUrlAction,
  SaveTerminalTransitionReportsFavorite,
  SearchTerminalTransitionReportsAction,
  SearchTerminalTransitionReportsDateRangeSlideAction,
  SearchTerminalTransitionReportsFieldsAction,
  SearchTerminalTransitionReportsMarkingAction,
  SearchTerminalTransitionReportsSortForHeaderAction,
  SearchTerminalTransitionReportsSortForTransitiveFieldAction,
  TerminalTransitionReportsSettingActionCreators,
  TerminalTransitionReportsSettingActionTypes,
  TerminalTransitionReportsSettingState,
  selectTerminalTransitionReportsColumnsForTransitionAction,
  terminalTransitionCurrentFieldsSelector,
  terminalTransitionReportsColumnsForTransitionOrderSelector,
  terminalTransitionReportsGraphShowNumberLabelSelector,
  terminalTransitionReportsGraphShowPlanSelector,
  terminalTransitionReportsGraphShowTotalSelector,
  terminalTransitionReportsSearchConditionSelector,
  terminalTransitionReportsSelectedDateRangeParamsSelector,
  terminalTransitionReportsSelectedFavoriteDataSelector,
  terminalTransitionReportsSelectedFavoritePageSettingSelector,
  terminalTransitionReportsSelectedFavoriteSelector,
  terminalTransitionReportsSettingCurrentHallsSelector,
  terminalTransitionReportsSettingSelector,
  terminalTransitionReportsTableFilterSelector,
} from '../redux/ui/terminalTransitionReportsSetting';
import { compressToEncodedURIComponent } from '../utils/compressToEncodedURIComponent';
import { dataTerminalTransitionDefaultValue } from '../utils/dataTerminalTransitionDefaultValue';
import { isValidArea } from '../utils/isValidArea';
import {
  getQueryParameterFromDataTerminalTransitionParams,
  selectShus2HallReportSearchCondition,
} from '../utils/shu';
import {
  DailyTransitiveDateRange,
  MonthlyTransitiveDateRange,
  WeeklyTransitiveDateRangesForTerminalTransition,
  makeDateForDailyFromDateRange,
  makeDateForMonthlyFromDateRange,
  makeDateForWeeklyFromDateRange,
} from '../utils/transitiveDateRange';

/**
 * 選択中の種別の情報を元に検索条件を加工する
 * @param selectedShu 選択中の種別の情報
 * @param searchParams 期間推移の検索条件
 * @returns 加工された検索条件
 */
export const queryParameterToSearchParams = (
  selectedShu: ShuOption[],
  searchParams: DataTerminalTransitionParams
): DataTerminalTransitionParams => {
  const selectedShuParams = selectShus2HallReportSearchCondition(selectedShu);
  // 必要に応じて検索パラメータを加工する
  return {
    ...searchParams,
    // 選択中の種別の情報を加える
    ...selectedShuParams,
    // MEMO: ForSijiritu 系のパラメータは検索条件で指定されているものを分母として渡す
    ...{
      shuGroupIdsForSijiritu: selectedShuParams.shuGroupIds,
      shuListForSijiritu: selectedShuParams.shuList,
    },
  };
};

/**
 * お気に入り選択時、データを取得する
 */
function* applyFavoriteByIdSaga() {
  // 現在選択中のお気に入りデータを取得
  const favorite: TerminalTransitionFavorite | undefined = yield select(
    terminalTransitionReportsSelectedFavoritePageSettingSelector
  );

  // デフォルトのお気に入りを選択した場合、リセットと同じ動作を行う
  if (favorite === undefined) {
    yield put(
      TerminalTransitionReportsSettingActionCreators.searchResetTerminalTransitionReportsAction()
    );
    // 項目絞込条件をデフォルト値にする
    yield put(
      TerminalTransitionReportsSettingActionCreators.changeTerminalTransitionCurrentFieldsAction(
        'all'
      )
    );

    // 推移グラフの数値ラベルをデフォルトにする
    yield put(
      TerminalTransitionReportsSettingActionCreators.selectGraphShowNumberLabelAction(
        true
      )
    );
    // 推移グラフの予定ラベルをデフォルトにする
    yield put(
      TerminalTransitionReportsSettingActionCreators.selectGraphShowPlanAction(
        true
      )
    );
    // 推移グラフの累計ラベルをデフォルトにする
    yield put(
      TerminalTransitionReportsSettingActionCreators.selectGraphShowTotalAction(
        true
      )
    );

    return;
  }

  // 期間推移のデータを初期化する
  yield fork(initTerminalTransitionReportsDataSaga);

  yield fork(applyFavoriteSaga, favorite);
}

/**
 * TerminalTransitionFavoriteの内容を実際に反映させる
 */
export function* applyFavoriteSaga(favorite: TerminalTransitionFavorite) {
  // 選択中の種別一覧に反映
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsShuAction(
      favorite.selectedShu
    )
  );

  // 期間を元にstartDateとendDateを算出
  let date:
    | ReturnType<typeof makeDateForMonthlyFromDateRange>
    | undefined = undefined;
  const dateType = favorite.selectedDateRangeParams.dateType;
  const dateRange = favorite.selectedDateRangeParams.dateRange;
  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    if (dateType === 'daily') {
      date = makeDateForDailyFromDateRange(
        dateRange as DailyTransitiveDateRange
      );
    }
    if (dateType === 'weekly') {
      date = makeDateForWeeklyFromDateRange(
        dateRange as WeeklyTransitiveDateRangesForTerminalTransition
      );
    }
    if (dateType === 'monthly') {
      date = makeDateForMonthlyFromDateRange(
        dateRange as MonthlyTransitiveDateRange
      );
    }
  }

  // 期間を再算出して上書きする
  // dateが算出されていない場合はカスタムと判断し、保持されている検索パラメータの値をそのまま送る
  const startDate =
    date !== undefined
      ? format(date.startDate, 'yyyy-MM-dd')
      : favorite.dataTerminalTransition.startDate;
  const endDate =
    date !== undefined
      ? format(date.endDate, 'yyyy-MM-dd')
      : favorite.dataTerminalTransition.endDate;

  if (!isValidArea(favorite.searchCondition.areas)) {
    yield put(
      ErrorActionCreators.setError(
        `お気に入りの検索条件に存在しないエリアが指定されています。\n正しいエリアを選択して検索を再度行い、お気に入りに保存してください。`
      )
    );
  }

  // 選択中の検索条件に反映
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsSearchConditionAction(
      {
        ...favorite.searchCondition,
        // 期間を再算出して上書きする
        startDate,
        endDate,
      }
    )
  );
  // dateType・dateRange（選択中の日付タイプ・期間）を更新する
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsDateRangeParamsAction(
      favorite.selectedDateRangeParams.dateType,
      favorite.selectedDateRangeParams.dateRange
    )
  );

  // 期間推移テーブルを取得
  yield put(
    DataTerminalTransitionActionCreators.fetchDataTerminalTransitionAction({
      ...dataTerminalTransitionDefaultValue().setting,
      ...favorite.dataTerminalTransition,
      // 期間を再算出して上書きする
      startDate,
      endDate,
    })
  );

  // テーブル列の推移項目並び順を変更
  if (favorite.columnForTransitionOrder) {
    yield put(
      TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsColumnsForTransitionAction(
        favorite.columnForTransitionOrder
      )
    );
  }

  // 検索条件 → エフェクトクリエイター（データ取得）に変換
  const tasks2ndRow = Object.keys(favorite.dataTerminalTransition2ndRow)
    .map((shuCode) => {
      const params = favorite.dataTerminalTransition2ndRow[shuCode];
      const queryParams = getQueryParameterFromDataTerminalTransitionParams(
        shuCode,
        params
      );
      if (queryParams !== undefined) {
        return put(
          DataTerminalTransition2ndRowActionCreators.fetchDataTerminalTransition2ndRowAction(
            queryParams,
            {
              ...params,
              // 期間を再算出して上書きする
              startDate,
              endDate,
              // 店舗フィルタで選択中の店舗のみの値を取得する
              halls:
                favorite.currentHalls ??
                favorite.dataTerminalTransition?.halls ??
                [],
            }
          )
        );
      }
      return undefined;
    })
    .filter((value) => value !== undefined);

  // お気に入りに登録されている期間推移テーブル展開行を全て取得
  yield all(tasks2ndRow);

  // テーブルフィルター項目を取得する
  // 項目一覧を反映する
  yield put(
    TerminalTransitionReportsSettingActionCreators.applyTerminalTransitionReportsTableFilterAction(
      favorite.tableFilterItems ?? []
    )
  );

  // 項目絞込の選択項目に反映
  yield put(
    TerminalTransitionReportsSettingActionCreators.changeTerminalTransitionCurrentFieldsAction(
      // 過去のお気に入りで空文字のケースがあるため、空文字の場合はallを選択する必要あり
      // 初期値undefinedなので、undefinedの場合もallを選択する必要あり
      favorite.selectedCurrentFieldCode || 'all'
    )
  );

  // 推移グラフデータに反映
  yield put(
    DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphAction(
      {
        ...favorite.searchCondition,
        startDate: startDate,
        endDate: endDate,
        field: favorite.dataTerminalTransitionGraph?.setting.field,
        shuGroupIds:
          favorite.dataTerminalTransitionGraph?.setting.shuGroupIds ??
          favorite.searchCondition.shuGroupIds,
        shuList:
          favorite.dataTerminalTransitionGraph?.setting.shuList ??
          favorite.searchCondition.shuList,
        halls:
          favorite.dataTerminalTransitionGraph?.setting.halls ??
          favorite.searchCondition.halls,
      }
    )
  );

  // 推移グラフの数値ラベルを反映
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectGraphShowNumberLabelAction(
      favorite.dataTerminalTransitionGraph?.showGraphNumberLabel ?? true
    )
  );
  // 推移グラフの予定ラベルを反映
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectGraphShowPlanAction(
      favorite.dataTerminalTransitionGraph?.showGraphPlan ?? true
    )
  );
  // 推移グラフの累計ラベルを反映
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectGraphShowTotalAction(
      favorite.dataTerminalTransitionGraph?.showGraphTotal ?? true
    )
  );

  // 店舗フィルタで選択中の店舗を更新する
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsCurrentHallsAction(
      favorite.currentHalls ?? favorite.dataTerminalTransition?.halls ?? []
    )
  );
}

/**
 * お気に入りに保存するデータの生成
 */
function* setPageSetting() {
  // 現在の期間推移の設定すべて
  const pageSetting: TerminalTransitionReportsSettingState = yield select(
    terminalTransitionReportsSettingSelector
  );
  // 現在の期間推移テーブルの検索条件
  const dataTerminalTransition: DataTerminalTransitionParams = yield select(
    dataTerminalTransitionSettingsSelector
  );
  // 現在の期間推移テーブルの展開行の検索条件
  const dataTerminalTransition2ndRow: {
    [key: string]: DataTerminalTransitionParams;
  } = yield select(dataTerminalTransition2ndRowSettingsSelector);

  // テーブルフィルターで選択中の項目
  const tableFilterSelectedItems: string[] | undefined = yield select(
    terminalTransitionReportsTableFilterSelector
  );

  // 店舗フィルタで選択中の項目
  const currentHalls: string[] = yield select(
    terminalTransitionReportsSettingCurrentHallsSelector
  );
  // 項目絞込で選択された項目
  const selectedCurrentFieldCode: string = yield select(
    terminalTransitionCurrentFieldsSelector
  );

  //推移グラフの検索条件
  const dataTerminalTransitionGraphSetting: DataTerminalTransitionGraphParams = yield select(
    dataTerminalTransitionGraphSearchConditionSelector()
  );

  //推移グラフの数値ラベル
  const dataTerminalTransitionGraphShowNumberLabel: boolean = yield select(
    terminalTransitionReportsGraphShowNumberLabelSelector
  );

  //推移グラフの予定ラベル
  const dataTerminalTransitionGraphShowPlanLabel: boolean = yield select(
    terminalTransitionReportsGraphShowPlanSelector
  );

  //推移グラフの累計ラベル
  const dataTerminalTransitionGraphShowTotalLabel: boolean = yield select(
    terminalTransitionReportsGraphShowTotalSelector
  );

  const result: FavoriteItem['pageSetting'] = {
    terminalTransition: {
      searchCondition: pageSetting.searchCondition,
      selectedShu: pageSetting.selectedShu,
      selectedDateRangeParams: pageSetting.selectedDateRangeParams,
      dataTerminalTransition,
      dataTerminalTransition2ndRow,
      columnForTransitionOrder: pageSetting?.columnsForTransitionOrder ?? [],
      tableFilterItems: tableFilterSelectedItems || [],
      currentHalls,
      selectedCurrentFieldCode: selectedCurrentFieldCode,
      dataTerminalTransitionGraph: {
        setting: dataTerminalTransitionGraphSetting,
        showGraphNumberLabel: dataTerminalTransitionGraphShowNumberLabel,
        showGraphPlan: dataTerminalTransitionGraphShowPlanLabel,
        showGraphTotal: dataTerminalTransitionGraphShowTotalLabel,
      },
    },
  };

  return result;
}

/**
 * お気に入り上書き保存時の処理
 */
function* saveFavoriteSaga(action: SaveTerminalTransitionReportsFavorite) {
  // 選択中のお気に入りID
  const selectedFavoriteId: number | undefined = yield select(
    terminalTransitionReportsSelectedFavoriteSelector
  );
  // 選択中のお気に入り
  const selectedFavorite: FavoriteItem = yield select(
    terminalTransitionReportsSelectedFavoriteDataSelector
  );
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // デフォルトの時は動作しない
  if (selectedFavoriteId === undefined) return;

  // お気に入りのデータを生成
  if (action.payload.mode === 'memo') {
    // お気に入りを上書き
    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
        selectedFavoriteId,
        {
          ...selectedFavorite,
          memo: action.payload.memo,
        }
      )
    );
  } else {
    // お気に入りを上書き
    yield put(
      SettingsFavoritesActionCreators.patchSettingsFavoritesAction(
        selectedFavoriteId,
        {
          name: action.payload.name,
          isShared: action.payload.isShared,
          pageSetting,
          memo: action.payload.memo,
          privatelySharedUsers: action.payload.sharedUser,
        }
      )
    );
  }
}

/**
 * お気に入り新規保存時の処理
 */
function* saveAsFavoriteSaga(action: SaveTerminalTransitionReportsFavorite) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  // お気に入りを新規登録
  yield put(
    SettingsFavoritesActionCreators.postSettingsFavoritesAction([
      {
        name: action.payload.name,
        isShared: action.payload.isShared,
        pageName: '期間推移',
        pageSetting,
        memo: action.payload.memo,
        privatelySharedUsers: action.payload.sharedUser,
      },
    ])
  );
}

/**
 * 期間推移の全てのデータを破棄する
 */
function* initTerminalTransitionReportsDataSaga() {
  yield put(
    DataTerminalTransition2ndRowActionCreators.renewDataTerminalTransition2ndRowAction()
  );
}

/**
 * 初回表示時に行う処理
 */
function* initActionSaga() {
  const loadingState: LoadingState = yield select(
    dataTerminalTransitionLoadingStateSelector
  );

  // ローディング中または既にエラーの場合は初回取得しない
  if (loadingState !== 'prepare') {
    return;
  }

  // 現在の検索条件を取得する
  const params: DataTerminalTransitionParams = yield select(
    terminalTransitionReportsSearchConditionSelector
  );

  // MEMO: デフォルトの検索条件でリクエスト
  yield put(
    DataTerminalTransitionActionCreators.fetchDataTerminalTransitionAction({
      ...params,
    })
  );

  // 取得済みのグラフの検索条件
  const graphSearchCondition: DataTerminalTransitionGraphParams = yield select(
    dataTerminalTransitionGraphSearchConditionSelector()
  );

  // デフォルトのグラフ条件でリクエスト(グラフの検索条件が空の場合のみ)
  if (Object.keys(graphSearchCondition).length === 0) {
    yield put(
      DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphAction(
        {}
      )
    );
  }
}

/**
 * 初回表示時のデータを取得する
 */
function* handleInitSearchSaga() {
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.INIT_TERMINAL_TRANSITION_REPORTS,
    initActionSaga
  );
}

/**
 * 検索ボタン押下時に行う処理
 */
function* searchActionSaga(action: SearchTerminalTransitionReportsAction) {
  // 期間推移のデータを初期化する
  yield fork(initTerminalTransitionReportsDataSaga);

  const searchParams = action.payload.params;
  const selectedShu = action.payload.selectedShu;
  // selectedShu（選択中の種別一覧）を更新する
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsShuAction(
      selectedShu
    )
  );

  const modifyParams = queryParameterToSearchParams(selectedShu, searchParams);

  // NOTE: paramsにdateTypeがセットされてないケースは想定していない
  const dateType = searchParams.dateType ?? 'daily';
  const dateRange = action.payload.selectedDateRange;

  // dateType・dateRange（選択中の日付タイプ・期間）を更新する
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsDateRangeParamsAction(
      dateType,
      dateRange
    )
  );

  // searchCondition(選択中の検索条件）を更新する
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsSearchConditionAction(
      modifyParams
    )
  );

  // データ取得
  yield put(
    DataTerminalTransitionActionCreators.fetchDataTerminalTransitionAction(
      modifyParams
    )
  );

  //推移グラフデータ取得
  yield put(
    DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphAction(
      {
        ...modifyParams,
      }
    )
  );
}

/**
 * 検索ボタン押下時、新しい検索条件でデータを取得する
 */
function* handleSearchSaga() {
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SEARCH_TERMINAL_TRANSITION_REPORTS,
    searchActionSaga
  );
}

/**
 * リセットボタン押下時、デフォルトの検索条件または選択中のお気に入りでデータを取得する
 */
function* resetSearchActionSaga() {
  // 現在選択中のお気に入りを取得する
  const favoriteId: number | undefined = yield select(
    terminalTransitionReportsSelectedFavoriteSelector
  );

  // お気に入りが選択されている場合はお気に入り適用
  if (favoriteId !== undefined) {
    yield put(
      TerminalTransitionReportsSettingActionCreators.changeTerminalTransitionReportsFavoriteAction(
        favoriteId
      )
    );
    return;
  }

  // 期間推移のデータを初期化する
  yield fork(initTerminalTransitionReportsDataSaga);

  // デフォルトが選択されている場合は検索条件を初期化
  yield put(
    TerminalTransitionReportsSettingActionCreators.resetTerminalTransitionReportsSearchConditionAction()
  );
  // 選択中の種別をリセットする
  yield put(
    TerminalTransitionReportsSettingActionCreators.resetTerminalTransitionReportsShuAction()
  );
  // 選択中の期間をリセットする
  yield put(
    TerminalTransitionReportsSettingActionCreators.resetTerminalTransitionReportsDateRangeParamsAction()
  );

  // 現在の検索条件を取得
  const searchCondition: DataTerminalTransitionParams = yield select(
    terminalTransitionReportsSearchConditionSelector
  );
  // 現在の期間を取得
  const dateRangeParams: TerminalTransitionReportsSettingDateRangeParams = yield select(
    terminalTransitionReportsSelectedDateRangeParamsSelector
  );

  // 必要に応じて検索パラメータを加工する
  let modifyParams = searchCondition;

  // 期間指定がカスタムの場合は現在の検索パラメータの値を送る
  if (dateRangeParams.dateRange !== 'カスタム') {
    // 指定された期間から日付を算出する
    const date =
      dateRangeParams.dateType === 'daily'
        ? makeDateForDailyFromDateRange(
            dateRangeParams.dateRange as DailyTransitiveDateRange
          )
        : dateRangeParams.dateType === 'weekly'
        ? makeDateForWeeklyFromDateRange(
            dateRangeParams.dateRange as WeeklyTransitiveDateRangesForTerminalTransition
          )
        : makeDateForMonthlyFromDateRange(
            dateRangeParams.dateRange as MonthlyTransitiveDateRange
          );

    const startDate = format(date.startDate, 'yyyy-MM-dd');
    const endDate = format(date.endDate, 'yyyy-MM-dd');
    modifyParams = { ...modifyParams, startDate, endDate };
  }

  // セルの並び順をリセットしてデフォルト順にする
  yield put(selectTerminalTransitionReportsColumnsForTransitionAction([]));

  // 項目絞込条件をデフォルト値にする
  yield put(
    TerminalTransitionReportsSettingActionCreators.changeTerminalTransitionCurrentFieldsAction(
      'all'
    )
  );

  //デフォルト値で推移グラフデータを取得する
  yield put(
    DataTerminalTransitionGraphActionCreators.fetchDataTerminalTransitionGraphAction(
      {}
    )
  );

  // データ取得
  yield put(
    DataTerminalTransitionActionCreators.fetchDataTerminalTransitionAction(
      modifyParams
    )
  );
}

/**
 * リセットボタン押下時期間推移の全てのデータを破棄する
 */
function* handleResetSaga() {
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SEARCH_RESET_TERMINAL_TRANSITION_REPORTS,
    resetSearchActionSaga
  );
}

/**
 * テーブル固有の検索条件を変更してデータを取得する
 * @param params 変更後の検索条件
 */
function* searchSearchConditonSaga(params: DataTerminalTransitionParams) {
  // 期間推移のデータを初期化する
  yield fork(initTerminalTransitionReportsDataSaga);

  // 現在の検索条件を取得
  const searchCondition: DataTerminalTransitionParams = yield select(
    terminalTransitionReportsSearchConditionSelector
  );

  // 店舗フィルタで選択中の項目
  const currentHalls: string[] = yield select(
    terminalTransitionReportsSettingCurrentHallsSelector
  );

  // 新しい検索条件
  const newSearchCondition = {
    ...searchCondition,
    ...params,
  };

  // 検索条件を更新
  yield put(
    TerminalTransitionReportsSettingActionCreators.selectTerminalTransitionReportsSearchConditionAction(
      newSearchCondition
    )
  );

  // データ取得
  yield put(
    DataTerminalTransitionActionCreators.fetchDataTerminalTransitionAction({
      ...newSearchCondition,
      halls: currentHalls.length === 0 ? searchCondition.halls : currentHalls,
    })
  );

  //推移グラフのデータを更新
  if (currentHalls.length > 0 && !params.startDate && !params.endDate) {
    yield put(
      DataTerminalTransitionGraphActionCreators.searchDataTerminalTransitionGraphHallAction(
        currentHalls
      )
    );
  }

  if (params.startDate && params.endDate) {
    yield put(
      DataTerminalTransitionGraphActionCreators.searchDataTerminalTransitionGraphDateAction(
        params.startDate,
        params.endDate
      )
    );
  }
}

/**
 * 非推移項目ソート実行時データを取得する
 */
function* searchSortForHeaderSaga(
  action: SearchTerminalTransitionReportsSortForHeaderAction
) {
  const params = {
    sortForHeader: action.payload.sort,
    order: action.payload.order,
    sortForTransitiveField: action.payload.transitiveSort,
  };
  yield fork(searchSearchConditonSaga, params);
}

/**
 * 推移項目ソート実行時データを取得する
 */
function* searchSortForTransitiveFieldSaga(
  action: SearchTerminalTransitionReportsSortForTransitiveFieldAction
) {
  // 現在の期間推移のデータを取得
  const terminalTransition: DataTerminalTransition = yield select(
    dataTerminalTransitionSelector
  );
  // 推移項目であるsortForTransitiveFieldを選択したとき、
  // sortForHeaderが日付項目でない場合にはデフォルト値として平均合計に変更する
  const sortForHeader = !terminalTransition.data.dates
    .map((date) => date.code)
    .includes(terminalTransition.setting.sortForHeader)
    ? terminalTransition.data.dates.at(0)?.code
    : terminalTransition.setting.sortForHeader;

  const params = {
    sortForHeader,
    sortForTransitiveField: action.payload.sort,
    order: action.payload.order,
  };

  yield fork(searchSearchConditonSaga, params);
}

/**
 * マーキング変更時データを取得する
 */
function* searchMarkingSaga(
  action: SearchTerminalTransitionReportsMarkingAction
) {
  yield fork(searchSearchConditonSaga, {
    marking:
      action.payload.markingOption.code ===
      MODEL_REPORT_MARKING_CONDITIONS.at(0)?.code
        ? undefined
        : action.payload.markingOption.code,
    isFiltering: action.payload.isFiltering,
  });
}

/**
 * 表示項目変更時データを取得する
 */
function* searchFieldsSaga(
  action: SearchTerminalTransitionReportsFieldsAction
) {
  // 現在の期間推移のデータを取得
  const terminalTransition: DataTerminalTransition = yield select(
    dataTerminalTransitionSelector
  );
  // 非推移項目が空のときは、メインフィールドのみを指定する
  const nonTransitiveFields =
    action.payload.nonTransitiveFields.length === 0
      ? [terminalTransition.setting.mainField]
      : action.payload.nonTransitiveFields;
  const params = {
    nonTransitiveFields,
    transitiveFields: action.payload.transitiveFields,
  };

  // 項目絞込で選択されている項目
  const selectedCurrentFieldCode: string = yield select(
    terminalTransitionCurrentFieldsSelector
  );

  // 表示項目に、項目絞込で選択されている項目が存在するか確認
  const hasCurrentFieldCode = params.transitiveFields.filter((field) =>
    field.includes(selectedCurrentFieldCode)
  );

  if (hasCurrentFieldCode.length === 0) {
    yield put(
      TerminalTransitionReportsSettingActionCreators.changeTerminalTransitionCurrentFieldsAction(
        'all'
      )
    );
  }

  yield fork(searchSearchConditonSaga, params);
}

/**
 * テーブル固有の検索条件変更検知時の処理
 */
function* handleTableSearchSaga() {
  // 非推移項目ソート実行時
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_HEADER,
    searchSortForHeaderSaga
  );

  // 推移項目ソート実行時
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SEARCH_TERMINAL_TRANSITION_REPORTS_SORT_FOR_TRANSITIVE_FIELD,
    searchSortForTransitiveFieldSaga
  );

  // マーキング実行時
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SEARCH_TERMINAL_TRANSITION_REPORTS_MARKING,
    searchMarkingSaga
  );

  // 表示項目変更時
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SEARCH_TERMINAL_TRANSITION_REPORTS_FIELDS,
    searchFieldsSaga
  );
}

/**
 * 表示項目の並び順を変更する
 */
export function* changeColumnsOrderSaga() {
  const fieldsForTransition: Column[] = yield select(
    dataTerminalTransitionColumnsForTransitionSelector
  );
  const fieldsForTransitionCode = fieldsForTransition.map(
    (field) => field.code
  );

  // テーブル列の並び順
  const columnsForTransitionOrder: string[] = yield select(
    terminalTransitionReportsColumnsForTransitionOrderSelector
  );

  const orderedForTransitionCode = [...fieldsForTransitionCode].sort((a, b) => {
    return columnsForTransitionOrder.indexOf(b) >
      columnsForTransitionOrder.indexOf(a)
      ? -1
      : 1;
  });

  yield put(
    selectTerminalTransitionReportsColumnsForTransitionAction(
      orderedForTransitionCode
    )
  );
}

/**
 * お気に入り関連のActionがDispatchされるのを監視
 */
function* handleFavoriteSaga() {
  // お気に入り選択
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.CHANGE_TERMINAL_TRANSITION_REPORTS_FAVORITE,
    applyFavoriteByIdSaga
  );
  // お気に入り上書き保存
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SAVE_TERMINAL_TRANSITION_REPORTS_FAVORITE,
    saveFavoriteSaga
  );
  // お気に入り新規保存
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SAVE_AS_TERMINAL_TRANSITION_REPORTS_FAVORITE,
    saveAsFavoriteSaga
  );
}

// 期間スライドコンポーネントがクリックされた時に検索期間を設定して再検索を実行する
function* searchDateRangeSlideSaga(
  action: SearchTerminalTransitionReportsDateRangeSlideAction
) {
  const { startDate, endDate } = action.payload;
  yield fork(searchSearchConditonSaga, { startDate, endDate });
}

// 期間スライドコンポーネントがクリックされた時に実行する処理
function* handleDateRangeSlideSaga() {
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.SEARCH_TERMINAL_TRANSITION_REPORTS_DATE_RANGE_SLIDE,
    searchDateRangeSlideSaga
  );
}

/**
 * 変更後の店舗でデータを取得する
 */
function* changeCurrentHallsSaga(
  action: ChangeTerminalTransitionReportsCurrentHallsAction
) {
  // 店舗が選択されていない場合は処理を中断
  if (action.payload.halls === undefined) return;

  // currentHallsの取得はsearchSearchConditonSaga内でやっているので
  // ここは空オブジェクトを渡して良い
  yield fork(searchSearchConditonSaga, {});
}

/**
 * 選択中の店舗変更時、データを取得する
 */
function* handleChangeCurrentHallsSaga() {
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.CHANGE_TERMINAL_TRANSITION_REPORTS_CURRENT_HALLS,
    changeCurrentHallsSaga
  );
}

/**
 * 画面共有用短縮URL作成の処理
 */
function* createShortenedUrlSaga(
  action: CreateTerminalTransitionReportsShortenedUrlAction
) {
  const pageSetting: FavoriteItem['pageSetting'] = yield call(setPageSetting);

  const compressed = compressToEncodedURIComponent(
    action.payload.pageName,
    pageSetting ?? {}
  );
  const originalUrl = `${action.payload.locationUrl}?q=${compressed}`;

  yield put(ShortenedUrlActionCreators.postShortenedUrlAction({ originalUrl }));
}

// 画面共有用短縮URL作成の処理
function* handleShortenedUrlSaga() {
  yield takeEvery(
    TerminalTransitionReportsSettingActionTypes.CREATE_TERMINAL_TRANSITION_REPORTS_SHORTENED_URL,
    createShortenedUrlSaga
  );
}

/**
 * 期間推移の検索フォームに関するタスクを実行する
 */
export function* terminalTransitionReportsSettingSaga() {
  yield fork(handleInitSearchSaga);
  yield fork(handleSearchSaga);
  yield fork(handleResetSaga);
  yield fork(handleTableSearchSaga);
  yield fork(handleFavoriteSaga);
  yield fork(handleDateRangeSlideSaga);
  yield fork(handleChangeCurrentHallsSaga);
  yield fork(handleShortenedUrlSaga);
}
