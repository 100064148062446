/**
 * [機械代登録] 表示項目名
 */
export const daiCostsFields = [
  { code: 'initialHallName', name: '初期導入店舗名' },
  { code: 'currentHallName', name: '現設置店舗名' },
  { code: 'mainBoardNo', name: '基板番号' },
  { code: 'kiMei', name: '機種名' },
  { code: 'donyuDay', name: '導入日' },
  { code: 'konyuKbn', name: '購入区分' },
  { code: 'konyuGaku', name: '購入金額' },
  { code: 'syoruiGaku', name: '書類代金' },
  { code: 'saleGaku', name: '売却代金' },
  { code: 'saleDay', name: '売却日' },
] as const;
