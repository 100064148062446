import { DataDepreciationSearchParams } from '../../domain/depreciation/types';

/* ---------------------------------------------------------------
 * Action Types
 */

const INIT_DEPRECIATION_SETTING = 'INIT_DEPRECIATION_SETTING' as const;
const SEARCH_DEPRECIATION_SETTING = 'SEARCH_DEPRECIATION_SETTING' as const;
const RENEW_DEPRECIATION_SETTING = 'RENEW_DEPRECIATION_SETTING' as const;
const SELECT_DEPRECIATION_FORM_CONDITION = 'SELECT_DEPRECIATION_FORM_CONDITION' as const;

export const DepreciationSettingActionTypes = {
  INIT_DEPRECIATION_SETTING,
  SEARCH_DEPRECIATION_SETTING,
  RENEW_DEPRECIATION_SETTING,
  SELECT_DEPRECIATION_FORM_CONDITION,
};

/* ---------------------------------------------------------------
 * Action Creators
 */

/**
 * 初回リクエスト
 */
function initDepreciationSettingAction() {
  return {
    type: INIT_DEPRECIATION_SETTING,
  };
}

/**
 * 検索ボタンの押下
 */
function searchDepreciationSettingAction(
  searchParams: DataDepreciationSearchParams
) {
  return {
    type: SEARCH_DEPRECIATION_SETTING,
    payload: { searchParams },
  };
}

function renewDepreciationSettingAction() {
  return {
    type: RENEW_DEPRECIATION_SETTING,
  };
}

/**
 * FormConditionを変更
 */
function selectDepreciationFormCondition(
  formCondition: DepreciationSettingState['searchParams']
) {
  return {
    type: SELECT_DEPRECIATION_FORM_CONDITION,
    payload: { formCondition },
  };
}

export const DepreciationSettingActionCreators = {
  initDepreciationSettingAction,
  searchDepreciationSettingAction,
  renewDepreciationSettingAction,
  selectDepreciationFormCondition,
};

/* ---------------------------------------------------------------
 * Actions
 */

type InitDepreciationSettingAction = ReturnType<
  typeof initDepreciationSettingAction
>;

type SearchDepreciationSettingAction = ReturnType<
  typeof searchDepreciationSettingAction
>;

type RenewDepreciationSettingAction = ReturnType<
  typeof renewDepreciationSettingAction
>;

type SelectDepreciationFormConditionAction = ReturnType<
  typeof selectDepreciationFormCondition
>;

type DepreciationSettingActions =
  | InitDepreciationSettingAction
  | SearchDepreciationSettingAction
  | RenewDepreciationSettingAction
  | SelectDepreciationFormConditionAction;

/* ---------------------------------------------------------------
 * State
 */

type DepreciationSettingState = {
  searchParams: DataDepreciationSearchParams | undefined;
};

const initialState: DepreciationSettingState = {
  searchParams: undefined,
};

/* ---------------------------------------------------------------
 * Selector
 */

/* ---------------------------------------------------------------
 * Reducer
 */

export function depreciationSettingReducer(
  state = initialState,
  action: DepreciationSettingActions
): DepreciationSettingState {
  switch (action.type) {
    case 'SEARCH_DEPRECIATION_SETTING':
      return {
        ...state,
        searchParams: action.payload.searchParams,
      };
    case 'RENEW_DEPRECIATION_SETTING':
      return initialState;
    default:
      return state;
    case 'SELECT_DEPRECIATION_FORM_CONDITION':
      return {
        ...state,
        searchParams: action.payload.formCondition,
      };
  }
}
