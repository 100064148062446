import { FC, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { SettingsOptionsDepreciationActionCreators } from '../../../redux/server/settingsOptionsDepreciation';
import { SettingsOptionsEventsActionCreators } from '../../../redux/server/settingsOptionsEvents';
import { SettingsOptionsKiActionCreators } from '../../../redux/server/settingsOptionsKi';
import { SettingsOptionsModeActionCreators } from '../../../redux/server/settingsOptionsMode';
import { SettingsOptionsSisActionCreators } from '../../../redux/server/settingsOptionsSis';
import { DaiCostsSettingActionCreators } from '../../../redux/ui/daiCostsSetting';
import { DepreciationSettingActionCreators } from '../../../redux/ui/depreciationSetting';
import { EventsSettingActionCreators } from '../../../redux/ui/eventsSetting';
import { ModeSettingActionCreators } from '../../../redux/ui/modeSettings';
import { ModelReportsSettingActionCreators } from '../../../redux/ui/modelReportsSetting';
import { SisSettingsActionCreators } from '../../../redux/ui/sisSettings';

export const InitRequest: FC = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  /**
   * パス名の末尾のスラッシュを削除
   *
   * Amplify環境ではパス名の末尾にスラッシュが付与されるため
   */
  const pathname = useMemo(() => location.pathname.replace(/\/$/, ''), [
    location.pathname,
  ]);

  useEffect(() => {
    switch (pathname) {
      case '/report/depreciation':
        dispatch(
          SettingsOptionsDepreciationActionCreators.fetchSettingsOptionsDepreciationAction()
        );
        dispatch(
          DepreciationSettingActionCreators.initDepreciationSettingAction()
        );
        return;
      case '/report/model':
        dispatch(ModelReportsSettingActionCreators.initModelReportsAction());
        // NOTE:
        // 機種集計のsettingsOptionsに含まれる機種タグは他のユーザーに変更される可能性があるため
        // 画面読み込みごとに都度取得する
        dispatch(
          SettingsOptionsKiActionCreators.fetchSettingsOptionsKiAction()
        );
        return;
      case '/report/sis':
        dispatch(SisSettingsActionCreators.initSisAction());
        dispatch(
          SettingsOptionsSisActionCreators.fetchSettingsOptionsSisAction()
        );
        return;
      case '/report/mode':
        dispatch(
          SettingsOptionsModeActionCreators.fetchSettingsOptionsModeAction()
        );
        dispatch(ModeSettingActionCreators.initDataModeAction());
        return;
      case '/settings/event':
        dispatch(EventsSettingActionCreators.initEventsSettingAction());
        dispatch(
          SettingsOptionsEventsActionCreators.initSettingsOptionsEventsAction()
        );
        return;
      case '/settings/daiCosts':
        dispatch(DaiCostsSettingActionCreators.initDaiCostsSettingAction());
        return;
    }
  }, [dispatch, pathname]);

  return <Outlet />;
};
