import { fork, put, select, take, takeEvery } from 'redux-saga/effects';

import { DataDepreciationResponse } from '../domain/depreciation/types';
import { LoadingState } from '../domain/schemas';

import {
  DataDepreciationActionCreators,
  DataDepreciationActionTypes,
  dataDepreciationDataSelector,
  dataDepreciationLoadingStateSelector,
} from '../redux/server/dataDepreciation';
import {
  DepreciationSettingActionCreators,
  DepreciationSettingActionTypes,
} from '../redux/ui/depreciationSetting';

/**
 * 初回データ取得
 */
function* initFetchDepreciationDataSaga() {
  const loadingStateDepreciation: LoadingState = yield select(
    dataDepreciationLoadingStateSelector
  );

  if (loadingStateDepreciation !== 'prepare') {
    return;
  }

  yield put(DataDepreciationActionCreators.fetchDataDepreciationAction());

  yield take(DataDepreciationActionTypes.FETCH_DATA_DEPRECIATION_SUCCESS);
  const dataDepreciation: DataDepreciationResponse = yield select(
    dataDepreciationDataSelector
  );
  yield put(
    DepreciationSettingActionCreators.selectDepreciationFormCondition(
      dataDepreciation.setting
    )
  );
}

/**
 * 初回表示時のデータ取得
 */
function* handleInitFetchSaga() {
  yield takeEvery(
    DepreciationSettingActionTypes.INIT_DEPRECIATION_SETTING,
    initFetchDepreciationDataSaga
  );
}

export function* depreciationSettingSaga() {
  yield fork(handleInitFetchSaga);
}
